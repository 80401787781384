import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDocs, collection, query, doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase/firebaseConfig';
import '../Carousel/Carousel.css';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);

  const fetchCarouselItems = async () => {
    try {
      const carouselCollectionRef = collection(db, 'carousel');
      const carouselQuery = query(carouselCollectionRef);
      const snapshot = await getDocs(carouselQuery);

      const items = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        items.push({ id: doc.id, image: data.img });
      });

      setCarouselItems(items);
    } catch (error) {
      console.error('Error fetching carousel items:', error);
    }
  };

  const nextSlide = () => {
    const newIndex = (activeIndex + 1) % carouselItems.length;
    setActiveIndex(newIndex);
  };

  const prevSlide = () => {
    const newIndex = (activeIndex - 1 + carouselItems.length) % carouselItems.length;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    fetchCarouselItems();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000); // Cambia de imagen cada 3000 milisegundos (3 segundos)

    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, [activeIndex, carouselItems]); // Dependencias adicionales para reiniciar el intervalo cuando cambia la imagen activa o los elementos del carrusel

  return (
    <div id="carouselExample" className="carousel slide">
      <div className="carousel-inner">
        {carouselItems.map((item, index) => (
          <Link
            key={index}
            to={`/item/${index === 0 ? 'IaLbWykwTBKOBM3Gi1ME' : 'xim3Ubw8NP6jQqklgBq5'}`}
            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
          >
            <img src={item.image} className="d-block w-100" alt={`Imágen ${index + 1}`} />
          </Link>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" onClick={prevSlide}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" onClick={nextSlide}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
