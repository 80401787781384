// CartCounter.js
import React, { useContext } from 'react';
import { CartContext } from '../../context/CartContext';

const CartCounter = () => {
  const { cart } = useContext(CartContext);

  // Calcular el total de todos los productos en el carrito
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <span className='cart-counter'>
      {totalItems >= 0 ? <span>{totalItems}</span> : <span>0</span>}
    </span>
  );
};

export default CartCounter;
