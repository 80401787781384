// FloatingIcon
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import CartPopup from '../CartPopup/CartPopup';
import '../FloatingIcon/FloatingIcon.css'
import { CartContext } from '../../context/CartContext';
import CartCounter from '../CartCounter/CartCounter';

const FloatingIcon = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { cart } = useContext(CartContext);

  const handleIconClick = () => {
    setPopupOpen(!popupOpen);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <div className='FloatIcon' onClick={handleIconClick}>
        <CartCounter />
        <FontAwesomeIcon icon={faShoppingCart} size='2x' />
      </div>
      {popupOpen && <CartPopup onClose={handleClosePopup} />}
    </div>
  );
};

export default FloatingIcon;
