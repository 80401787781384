//CartPopup
import React, { useContext, useRef, useEffect } from 'react';
import { CartContext } from '../../context/CartContext';
import './CartPopup.css';
import { NavLink } from 'react-router-dom';

// Función para formatear el precio con separador de miles
const formatPrice = (value) => {
  return new Intl.NumberFormat('es-CL').format(value);
};
// Función para calcular el subtotal del carrito
const calculateSubtotal = (cart) => {
  return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
};

// Función para calcular el total del carrito (suponiendo un impuesto del 19%)
const calculateTotal = (cart) => {
  const subtotal = calculateSubtotal(cart);
  const tax = subtotal * 0.19;
  return subtotal + tax;
};
const CartPopup = ({ onClose }) => {
  const { cart, removeItem, clearCart } = useContext(CartContext);

  const handleRemoveItem = (itemId) => {
    removeItem(itemId);
  };

  const handleClearCart = () => {
    clearCart();
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="cart-popup" ref={modalRef}>
       <div className="container text-center">
        <div className="row align-items-start">
          <div className="col">
            <h4>Tu Carrito</h4>
          </div>
          <div className="col">
            <button onClick={onClose}>X</button>
          </div>
        </div>
      </div>
      <div className='Separator'></div>

      {cart.length === 0 ? (
        <div className="container text-center">
          <div className="row align-items-start">
            <div className="col-sm-12 p-3">
              <p>Tu carrito está vacío</p>
              <button className="btnCarPoup" onClick={onClose}>
                Seguir comprando
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="cart-items-container">
            {cart.map((item) => (
              <div key={item.id}>
                <p>Nombre: {item.nombre}</p>
                <p>Precio: ${formatPrice(item.price)}</p>
                <p>Cantidad: {item.quantity}</p>
                <button onClick={() => handleRemoveItem(item.id)}>Eliminar</button>
                <div className='Separator'></div>
              </div>
            ))}
          </div>
          <hr />
          <div className="totals">
      <p id='TxtBold'>Subtotal: ${formatPrice(calculateSubtotal(cart)).toLocaleString()}</p>
      <p id='TxtBold'>Total(+19%): ${formatPrice(calculateTotal(cart)).toLocaleString()}</p>
    </div>
          <div className="container text-center">
              <div className="row align-items-start">
              <div className="col-sm-12 p-3">
                <button className="btnCarPoup" onClick={onClose}>
                  Seguir comprando
                </button>
                <NavLink to="/Cart" className="btnCarPoup">
                  Ver carrito
                </NavLink>
                <NavLink to="/checkout" className="btnCarPoup">
                  Finalizar Compra
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPopup;
